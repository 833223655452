import { Button } from '@/components/ui/button';
import { extractErrorMessage } from '@/errors/utilities';
import {
  AdvanceTrackingInfo,
  CollectionWithShipmentCountsFragment,
  DeleteCollectionInput,
  DeleteCollectionMutation,
  LiveTrackingInfo,
  OrganizationFragment,
  ProofOfCollectionFragment,
  ProofOfCompletionStatus,
  SkipCollectionInput,
  SkipCollectionMutation,
  useCheckCollectionCancellationTermsLazyQuery,
} from '@/generated/graphql';
import { cn } from '@/lib/utils';
import { formatProofOfCompletionStatus } from '@/utilities/proof-of-completion';
import { FetchResult } from '@apollo/client';
import { nowLocal, parseOptionalInstant } from '@packfleet/datetime';
import { Map } from 'lucide-react';
import React, { useState } from 'react';
import { useOrganizationTimezone } from '../../hooks/timezone';
import { formatCollectionLocation } from '../../utilities/collection-locations';
import { formatCollection } from '../../utilities/collections';
import { formatTime } from '../../utilities/date';
import { pluralize } from '../../utilities/pluralize';
import { formatStartEndTime } from '../../utilities/time';
import WithAlert from '../modal/WithAlert';
import CollectionTrackingMap from '../tracking/CollectionTrackingMap';
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from '../ui/card';
import { CollectionTerms } from './CollectionTerms';

export type Props = {
  collection: CollectionWithShipmentCountsFragment;
  organization: OrganizationFragment;
  liveTracking?: LiveTrackingInfo | null;
  proofOfCollection?: ProofOfCollectionFragment | null;
  advanceTracking?: AdvanceTrackingInfo | null;
  onSkipCollection: (
    input: SkipCollectionInput,
  ) => Promise<FetchResult<SkipCollectionMutation>>;
  onDeleteCollection: (
    input: DeleteCollectionInput,
  ) => Promise<FetchResult<DeleteCollectionMutation>>;
};

const LiveCollectionCard = ({
  collection,
  organization,
  liveTracking,
  advanceTracking,
  onSkipCollection,
  onDeleteCollection,
  proofOfCollection,
}: Props) => {
  const [errorMessage, setErrorMessage] = useState('');
  const orgTimezone = useOrganizationTimezone();
  const [checkCollectionCancellationTerms, { data, error }] =
    useCheckCollectionCancellationTermsLazyQuery({
      variables: { input: { collectionId: collection.id } },
      fetchPolicy: 'network-only',
    });
  const checkTermsError =
    error?.message || data?.checkCollectionCancellationTerms?.error?.message;
  const terms = data?.checkCollectionCancellationTerms?.terms;

  const numShipments = collection.numShipments ?? 0;
  const numPacks = collection.numPacks ?? 0;
  const allShipmentsCollected = collection.allShipmentsCollected;
  const numExternalShipments = collection.numExternalShipments ?? 0;
  const totalShipmentCount = numShipments + numExternalShipments;

  const isSkipped = collection.skipped || collection.deletedAt != null;
  const driverName = liveTracking?.driverName;
  const eta = parseOptionalInstant(liveTracking?.estimatedTime);
  const windowStart = parseOptionalInstant(liveTracking?.timeWindowStart);
  const windowStartZoned = windowStart?.toZonedDateTimeISO(orgTimezone);
  const windowEnd = parseOptionalInstant(liveTracking?.timeWindowEnd);
  const windowEndZoned = windowEnd?.toZonedDateTimeISO(orgTimezone);
  const advanceWindowStart = parseOptionalInstant(
    advanceTracking?.timeWindowStart,
  );
  const advanceWindowStartZoned =
    advanceWindowStart?.toZonedDateTimeISO(orgTimezone);
  const advanceWindowEnd = parseOptionalInstant(advanceTracking?.timeWindowEnd);
  const advanceWindowEndZoned =
    advanceWindowEnd?.toZonedDateTimeISO(orgTimezone);
  const now = nowLocal(orgTimezone);
  const minsAway = eta
    ?.toZonedDateTimeISO(orgTimezone)
    .since(now)
    .total({ unit: 'minutes' });
  const showMinsAway = minsAway != null && minsAway < 60;
  const driverIsClose = minsAway != null && minsAway < 5;
  const collectionCompleted = liveTracking?.completedAt != null;

  const collectionFailed =
    proofOfCollection?.status &&
    proofOfCollection?.status !== ProofOfCompletionStatus.Collected;

  const collectionStatus = collectionFailed
    ? formatProofOfCompletionStatus(proofOfCollection.status)
    : undefined;

  const { isReturnLocation } = collection.location;

  return (
    <Card className="flex flex-1 w-full flex-col min-h-96 overflow-hidden justify-between h-full bg-card">
      <div>
        <div className="flex w-full">
          <div className="lg:min-h-full h-36 w-full lg:max-w-xl lg:flex-1 overflow-hidden">
            {liveTracking && !collectionCompleted ? (
              <CollectionTrackingMap
                senderEmoji={organization.emoji}
                liveInfo={liveTracking}
              />
            ) : (
              <div className="w-full h-full bg-black/10 flex items-center justify-center">
                <Map className="size-6 text-muted-foreground" />
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-col">
          <CardHeader>
            <CardTitle>
              {formatStartEndTime(collection.startTime, collection.endTime) ||
                'Any time'}
            </CardTitle>
            <CardDescription>
              {isReturnLocation ? 'A return ' : null}from{' '}
              {formatCollectionLocation(collection.location)}
            </CardDescription>
          </CardHeader>
          <CardContent className="-mt-2">
            {!isSkipped && !collectionCompleted ? (
              <CardDescription
                className={cn(
                  'pb-4',
                  totalShipmentCount === 0
                    ? 'text-warning-foreground'
                    : undefined,
                )}
              >
                {isReturnLocation ? null : totalShipmentCount === 0 ? (
                  <span className="font-medium">
                    You havenʼt added any shipments
                  </span>
                ) : allShipmentsCollected ? (
                  <span className="flex items-center gap-1 mt-1">
                    All shipments collected{' '}
                  </span>
                ) : (
                  `${totalShipmentCount} ${pluralize(
                    totalShipmentCount,
                    'shipment',
                    'shipments',
                  )}${
                    numPacks !== numShipments
                      ? ` (${numPacks} ${pluralize(numPacks, 'pack', 'packs')})`
                      : ''
                  } to collect`
                )}
              </CardDescription>
            ) : null}
            <CardDescription>
              {collectionCompleted ? (
                <>
                  {collectionFailed ? (
                    <div>
                      <div className="text-danger">{collectionStatus}</div>
                      {proofOfCollection?.notes}
                    </div>
                  ) : (
                    <span>Collection completed</span>
                  )}
                </>
              ) : liveTracking ? (
                <span>
                  {driverName ?? 'Your driver'}{' '}
                  {driverIsClose ? (
                    <span>is a few minutes away</span>
                  ) : showMinsAway ? (
                    <span>is {minsAway.toFixed()} min away</span>
                  ) : windowStartZoned && windowEndZoned ? (
                    <span>
                      will arrive between
                      <br /> {formatTime(windowStartZoned.toPlainTime())} and{' '}
                      {formatTime(windowEndZoned.toPlainTime())}
                    </span>
                  ) : advanceWindowStartZoned && advanceWindowEndZoned ? (
                    <span>
                      <span>
                        will arrive between <br />{' '}
                        {formatTime(advanceWindowStartZoned.toPlainTime())} and{' '}
                        {formatTime(advanceWindowEndZoned.toPlainTime())}
                      </span>
                    </span>
                  ) : (
                    <span>is on the way to you</span>
                  )}
                </span>
              ) : (
                <span>
                  Weʼll assign you a driver and 1-hour ETA window soon.
                </span>
              )}
            </CardDescription>
          </CardContent>
        </div>
      </div>
      <CardFooter className="empty:p-0">
        {totalShipmentCount === 0 && !collectionCompleted ? (
          <WithAlert
            button={
              <Button size="sm" variant="outline">
                {isReturnLocation ? 'Skip this return' : 'Skip collection'}
              </Button>
            }
            onOpen={async () => {
              await checkCollectionCancellationTerms();
            }}
            title={
              isReturnLocation
                ? 'Cancel this return collection?'
                : 'Skip this collection?'
            }
            body={
              <div>
                <p className="mb-2">
                  Weʼll no longer come to collect{' '}
                  {formatCollection(collection, orgTimezone, true)}
                </p>
                {checkTermsError ? (
                  <p className="text-danger mb-2">
                    Something went wrong checking the cancllation conditions
                  </p>
                ) : null}
                <CollectionTerms terms={terms} tz={orgTimezone} />
              </div>
            }
            confirmText={'Yes, Skip'}
            onConfirm={async () => {
              if (collection.recurringCollectionId != null) {
                const result = await onSkipCollection({
                  collectionId: collection.id,
                  feeAccepted: terms?.cancellationFee
                    ? {
                        amount: terms.cancellationFee.amount,
                        currencyCode: terms.cancellationFee.currencyCode,
                      }
                    : null,
                });
                const errorMsg = extractErrorMessage(result, 'skipCollection');
                if (errorMsg) {
                  setErrorMessage(errorMsg);
                } else {
                  setErrorMessage('');
                }
              } else {
                const result = await onDeleteCollection({
                  collectionId: collection.id,
                  feeAccepted: terms?.cancellationFee
                    ? {
                        amount: terms.cancellationFee.amount,
                        currencyCode: terms.cancellationFee.currencyCode,
                      }
                    : null,
                });
                const errorMsg = extractErrorMessage(
                  result,
                  'deleteCollection',
                );
                if (errorMsg) {
                  setErrorMessage(errorMsg);
                } else {
                  setErrorMessage('');
                }
              }
            }}
            cancelText="Cancel"
            onCancel={() => null}
          />
        ) : null}

        {errorMessage && (
          <span className="text-danger mt-4">{errorMessage}</span>
        )}
      </CardFooter>
    </Card>
  );
};

export default LiveCollectionCard;
