var __defProp = Object.defineProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};

// src/useBreakpoint.ts
import { useLayoutEffect, useState } from "react";

// src/utils/breakpoints.ts
var breakpoints = {
  xs: 0,
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  "2xl": 1536
};

// src/useBreakpoint.ts
var getBreakpoint = () => {
  const width = window.innerWidth;
  if (width < breakpoints.sm) {
    return "xs";
  } else if (width < breakpoints.md) {
    return "sm";
  } else if (width < breakpoints.lg) {
    return "md";
  } else if (width < breakpoints.xl) {
    return "lg";
  }
  return "xl";
};
function useBreakpoint() {
  const [breakpoint, setBreakpoint] = useState("xs");
  useLayoutEffect(() => {
    const calculateBreakpoint = () => setBreakpoint(getBreakpoint());
    window.addEventListener("resize", calculateBreakpoint);
    calculateBreakpoint();
    return () => window.removeEventListener("resize", calculateBreakpoint);
  }, []);
  return breakpoint;
}
function useMinimumBreakpoint(breakpoint) {
  return breakpoints[useBreakpoint()] >= breakpoints[breakpoint];
}
function useMaximumBreakpoint(breakpoint) {
  return breakpoints[useBreakpoint()] <= breakpoints[breakpoint];
}

// src/useCopyToClipboard.ts
import { useEffect, useState as useState2 } from "react";
function useCopyToClipboard() {
  const [copiedCount, setCopiedCount] = useState2(0);
  const copyToClipboard = async (text) => {
    await navigator.clipboard.writeText(text);
    setCopiedCount((c) => c + 1);
  };
  useEffect(() => {
    if (copiedCount) {
      const timeout = setTimeout(() => {
        setCopiedCount(0);
      }, 2e3);
      return () => clearTimeout(timeout);
    }
  }, [copiedCount]);
  return { copied: copiedCount > 0, copyToClipboard };
}

// src/useQueryString.ts
import { useRouter } from "next/router";
import { useEffect as useEffect2, useState as useState3 } from "react";
var useQueryString = (key) => {
  const router = useRouter();
  const value = router.query[key];
  return typeof value === "string" ? value : void 0;
};
var useRequiredQueryString = (key) => {
  const value = useQueryString(key);
  if (!value) {
    throw new Error(`${key} not defined`);
  }
  return value;
};
var useAsyncQueryString = (key) => {
  const { query, isReady } = useRouter();
  const [value, setValue] = useState3(null);
  const latestValue = query[key];
  useEffect2(() => {
    if (isReady) {
      setValue(typeof latestValue === "string" ? latestValue : void 0);
    }
  }, [isReady, latestValue]);
  return value;
};

// src/useElementInView.ts
import { useCallback, useEffect as useEffect3, useState as useState4 } from "react";
var useElementInView = (observerTarget, onElementInView, onElementOutOfView) => {
  useEffect3(() => {
    const target = observerTarget.current;
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        onElementInView();
      } else {
        onElementOutOfView == null ? void 0 : onElementOutOfView();
      }
    }, { threshold: 1 });
    if (target) {
      observer.observe(target);
    }
    return () => {
      if (target)
        observer.unobserve(target);
    };
  }, [observerTarget.current, onElementInView, onElementOutOfView]);
};
var useHasBeenViewed = (observerTarget) => {
  const [isInView, setIsInView] = useState4(false);
  const inView = useCallback(() => setIsInView(true), []);
  useElementInView(observerTarget, inView);
  return isInView;
};
var useIsInView = (observerTarget) => {
  const [isInView, setIsInView] = useState4(false);
  const inView = useCallback(() => setIsInView(true), []);
  const notInView = useCallback(() => setIsInView(false), []);
  useElementInView(observerTarget, inView, notInView);
  return isInView;
};

// src/usePollWhenFocused.ts
import { useCallback as useCallback2, useEffect as useEffect5, useRef } from "react";

// src/useIsTabVisible.ts
import { useEffect as useEffect4, useState as useState5 } from "react";
function useIsTabVisible() {
  const [isVisible, setIsVisible] = useState5(false);
  useEffect4(() => {
    const updateIsVisible = () => setIsVisible(document.visibilityState !== "hidden");
    updateIsVisible();
    window.addEventListener("visibilitychange", updateIsVisible);
    return () => {
      window.removeEventListener("visibilitychange", updateIsVisible);
    };
  }, []);
  return isVisible;
}

// src/usePollWhenFocused.ts
function usePollWhenFocused({ interval, refetch, skip }) {
  const isTabVisible = useIsTabVisible();
  const firstRender = useRef(true);
  const refetchIfNotSkipped = useCallback2(() => {
    if (!skip) {
      refetch();
    }
  }, [refetch, skip]);
  useEffect5(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else if (isTabVisible) {
      refetchIfNotSkipped();
    }
    if (isTabVisible) {
      const intervalId = setInterval(() => refetchIfNotSkipped(), interval);
      return () => clearInterval(intervalId);
    }
  }, [interval, isTabVisible, refetchIfNotSkipped]);
}

// src/useSavedState.ts
import { get, set } from "idb-keyval";
import { useCallback as useCallback3, useEffect as useEffect6, useState as useState6 } from "react";
function useSavedState(key, defaultValue) {
  const [value, setValue] = useState6(defaultValue);
  useEffect6(() => {
    void (async () => {
      const storedValue = await get(key);
      if (storedValue !== void 0) {
        setValue(storedValue);
      }
    })();
  }, [key]);
  const onChange = useCallback3((updateFn) => {
    setValue((value2) => {
      const newValue = updateFn(value2);
      void set(key, newValue);
      return newValue;
    });
  }, [key, setValue]);
  return [value, onChange];
}

// src/useSetState.ts
import { useCallback as useCallback4, useState as useState7 } from "react";
var useSetState = (initialState) => {
  const [value, setValue] = useState7(() => new Set(initialState));
  const add = useCallback4((...values) => {
    setValue((prev) => {
      const newState = new Set(prev);
      for (const v of values) {
        newState.add(v);
      }
      return newState;
    });
  }, []);
  const remove = useCallback4((...values) => {
    setValue((prev) => {
      const newState = new Set(prev);
      for (const v of values) {
        newState.delete(v);
      }
      return newState;
    });
  }, []);
  const toggle = useCallback4((...values) => {
    setValue((prev) => {
      const newState = new Set(prev);
      for (const v of values) {
        if (newState.has(v)) {
          newState.delete(v);
        } else {
          newState.add(v);
        }
      }
      return newState;
    });
  }, []);
  const clear = useCallback4(() => {
    setValue(/* @__PURE__ */ new Set());
  }, []);
  return [value, { add, remove, toggle, clear }];
};

// src/utils/index.ts
var utils_exports = {};
__export(utils_exports, {
  breakpoints: () => breakpoints
});
export {
  useAsyncQueryString,
  useBreakpoint,
  useCopyToClipboard,
  useElementInView,
  useHasBeenViewed,
  useIsInView,
  useIsTabVisible,
  useMaximumBreakpoint,
  useMinimumBreakpoint,
  usePollWhenFocused,
  useQueryString,
  useRequiredQueryString,
  useSavedState,
  useSetState,
  utils_exports as utils
};
