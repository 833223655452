import {
  ApolloClientOptions,
  ApolloLink,
  InMemoryCache,
  Reference,
} from '@apollo/client';
import { mergePaginatedNodes } from '../utilities/apollo-cache/merge-paginated-nodes';

export const apolloConfig = (link: ApolloLink): ApolloClientOptions<any> => {
  return {
    ssrMode: typeof window === 'undefined', // set to true for SSR
    connectToDevTools: true,
    link: link,
    cache: new InMemoryCache({
      possibleTypes: {
        ThirdPartyAppSession: [
          'BigCommerceSession',
          'LinnworksSession',
          'MagentoSession',
          'MintsoftSession',
          'ShopifyShop',
          'SquarespaceSession',
          'VeeqoSession',
          'WooCommerceSession',
        ],
      },
      typePolicies: {
        Query: {
          fields: {
            listNextShipmentServices: {
              keyArgs: [
                'input',
                [
                  'collectionDate',
                  'numPacks',
                  'largestPackDimensions',
                  'deliveryEndTime',
                  'deliveryStartTime',
                ],
              ],
            },
            merchantAnalytics: {
              keyArgs: ['input', ['startDate', 'endDate', 'carriers']],
            },
            listShipments: {
              keyArgs: (args) => {
                if (
                  args?.input?.orderBy ||
                  args?.input?.deliveryDate ||
                  args?.input?.collectionDate ||
                  args?.input?.createdAt ||
                  args?.input?.labelsPrinted ||
                  args?.input?.collectonLocationId
                ) {
                  return [
                    'input',
                    [
                      'status',
                      'orderBy',
                      'deliveryDate',
                      'collectionDate',
                      'createdAt',
                      'labelsPrinted',
                      'collectionLocationId',
                    ],
                  ];
                }

                return false;
              },
              merge: mergePaginatedNodes,
            },

            listCollections: {
              keyArgs: ['input', ['fromDate', 'organizationId']],
            },

            listThirdPartyAppImportableShipments: {
              keyArgs: [],
              merge: (
                existing: { shipments: Reference[] } | undefined,
                incoming: { shipments: Reference[] },
              ): { shipments: Reference[] } => {
                if (!existing) {
                  return incoming;
                }
                const filtered: Reference[] = [];
                const seen = new Set<string>();
                const allEdges = [...existing.shipments, ...incoming.shipments];

                for (const pr of allEdges) {
                  if (seen.has(pr.__ref)) {
                    continue;
                  }
                  seen.add(pr.__ref);
                  filtered.push(pr);
                }
                return {
                  ...incoming,
                  shipments: filtered,
                };
              },
            },

            searchShipments: {
              keyArgs: (args) => {
                if (
                  args?.input?.orderBy ||
                  args?.input?.deliveryDate ||
                  args?.input?.collectionDate ||
                  args?.input?.createdAt ||
                  args?.input?.labelsPrinted ||
                  args?.input?.collectonLocationId ||
                  args?.input?.query
                ) {
                  return [
                    'input',
                    [
                      'status',
                      'orderBy',
                      'deliveryDate',
                      'collectionDate',
                      'createdAt',
                      'labelsPrinted',
                      'collectionLocationId',
                      'query',
                    ],
                  ];
                }

                return false;
              },
              merge: mergePaginatedNodes,
            },
          },
        },
      },
    }),
  };
};
