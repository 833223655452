import { Button } from '@/components/ui/button';
import { NavBar } from '@components/navbar/NavBar';
import { Inter } from '@next/font/google';
import cs from 'classnames';
import React, { ReactNode } from 'react';
import { useIntercom } from 'react-use-intercom';
import { useLoggedInAs } from '../../hooks/useLoggedInAs';
import { logoutAs } from '../../utilities/request/auth';
import HeadTag, { Meta } from '../head-tag/HeadTag';
import { SidebarProvider } from '../ui/sidebar';

const inter = Inter({
  subsets: ['latin'],
  weight: ['400', '500', '600', '700'],
  display: 'swap',
  variable: '--font-inter',
});

export type Props = {
  title: string;
  customHeader?: React.ReactNode;
  fullHeight?: boolean;
  fixedHeight?: boolean;
  sidebar?: React.ReactElement;
  meta?: Meta;
  hideNavbar?: boolean;
  intercomEnabled?: boolean;
  robotsIndexEnabled?: boolean;
  children?: ReactNode;
  className?: string;
  style?: React.CSSProperties;
};

const Page = ({
  title,
  children,
  className,
  fullHeight,
  fixedHeight,
  style,
  hideNavbar,
  meta = {},
  intercomEnabled = false,
  robotsIndexEnabled = false,
}: Props) => {
  const { loggedInAs, impersonatedBy } = useLoggedInAs();

  const intercom = useIntercom();
  if (intercomEnabled && !impersonatedBy) {
    intercom.boot();
  } else {
    intercom.shutdown();
  }

  const impersonatedByStyles = {
    '--impersonated-by-offset-x': impersonatedBy ? '16px' : '0px',
    '--impersonated-by-offset-y': impersonatedBy ? '48px' : '0px',
  } as React.CSSProperties;

  return (
    <SidebarProvider defaultOpen={false}>
      <HeadTag
        title={title}
        meta={meta}
        robotsIndexEnabled={robotsIndexEnabled}
      >
        <style jsx global>{`
          html {
            font-family: ${inter.style.fontFamily};
          }
        `}</style>
      </HeadTag>
      {hideNavbar ? null : (
        <NavBar
          style={impersonatedByStyles}
          className={
            impersonatedBy
              ? 'pl-[--impersonated-by-offset-x] pb-[--impersonated-by-offset-y]'
              : ''
          }
        />
      )}
      <div
        className="w-full flex-col bg-muted md:flex-row overflow-hidden"
        style={{
          minHeight: fullHeight ? '100%' : undefined,
          height: fixedHeight ? '100%' : undefined,
          ...style,
        }}
      >
        <main
          className={cs('relative flex w-full', `${inter.variable}`, className)}
          style={{
            minHeight: fullHeight ? '100%' : undefined,
            height: fixedHeight ? '100%' : undefined,
            ...style,
            ...impersonatedByStyles,
          }}
        >
          {children}
        </main>
      </div>
      {impersonatedBy && loggedInAs ? (
        <>
          <div className="fixed bottom-0 w-full bg-warning-foreground p-2 text-center text-attention font-medium">
            <div className="flex items-center justify-center">
              <div>
                Youʼre logged in as {loggedInAs.email} (
                {loggedInAs.organization?.name}
                ).
              </div>
              <div>
                <form
                  method="POST"
                  onSubmit={async (e) => {
                    e.preventDefault();
                    await logoutAs();
                    window.location.reload();
                  }}
                >
                  <Button
                    type="submit"
                    size="sm"
                    variant="secondary"
                    className="ml-2"
                  >
                    Stop running as
                  </Button>
                </form>
              </div>
            </div>
          </div>
          <div className="fixed bg-warning-foreground z-20 top-0 bottom-0 w-4 left-0" />
          <div className="fixed bg-warning-foreground z-20 top-0 bottom-0 w-4 right-0" />
          <div className="fixed bg-warning-foreground z-20 left-0 right-0 h-4 top-0" />
        </>
      ) : null}
    </SidebarProvider>
  );
};

export default Page;
