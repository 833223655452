import { CollectionLocationsSelector } from '@/components/collections/CollectionLocationsSelector';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { CalendarInput } from '@/components/ui/calendar-input';
import { Checkbox } from '@/components/ui/checkbox';
import { FormItem } from '@/components/ui/form';
import { Label } from '@/components/ui/label';
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group';
import { Separator } from '@/components/ui/separator';
import { Switch } from '@/components/ui/switch';
import {
  CollectionLocationFragment,
  ExternalCarrierPayloadFragment,
  OrderDirection,
  ShipmentOrderField,
} from '@/generated/graphql';
import { useOrganizationTimezone } from '@/hooks/timezone';
import { useCurrentUser } from '@/hooks/useCurrentUser';
import { useListCarriers } from '@/hooks/useListCarriers';
import { cn } from '@/lib/utils';
import { formatDate, formatFriendlyDate } from '@/utilities/date';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@components/ui/popover';
import {
  parsePlainDate,
  plainDateToJSDate,
  todayLocal,
} from '@packfleet/datetime';
import { Shipment } from '@packfleet/ui';
import { capitalize } from 'lodash';
import { ArrowDown, ArrowUp, ArrowUpDown, ListFilter, X } from 'lucide-react';
import { useRouter } from 'next/router';
import React, { ReactNode, useContext } from 'react';
import { useCollectionLocations } from './useCollectionLocations';
import { useSortShipmentsState } from './useSortShipmentsState';

export type CarrierCode = ReturnType<
  typeof useListCarriers
>['carriers'][0]['carrierCode'];

type CollectionLocations = ReturnType<
  typeof useCollectionLocations
>['collectionLocations'];

const formatSelectedFilterCarriers = (
  filterCarriers: Set<CarrierCode>,
  availableCarriers: ExternalCarrierPayloadFragment[],
) => {
  if (filterCarriers.size === 0) return '';

  const carriersByCode = Object.fromEntries(
    availableCarriers.map((c) => [c.carrierCode, c.name]),
  );
  const carrierNames = Array.from(filterCarriers).map(
    (c) => carriersByCode[c] ?? capitalize(c),
  );
  const lastCarrier = carrierNames.pop();

  return carrierNames.length
    ? `${carrierNames.join(', ')} or ${lastCarrier}`
    : lastCarrier;
};

const ORDER_BY_OPTIONS = [
  {
    label: 'Collection date',
    value: ShipmentOrderField.CollectionDate,
  },
  {
    label: 'Date created',
    value: ShipmentOrderField.CreatedAt,
  },
  {
    label: 'Reference',
    value: ShipmentOrderField.ExternalReference,
  },
  {
    label: 'Customer name',
    value: ShipmentOrderField.DeliveryLocationName,
  },
  {
    label: 'Postcode',
    value: ShipmentOrderField.PostCode,
  },
];

const ORDER_DIRECTION_OPTIONS = [OrderDirection.Asc, OrderDirection.Desc];

const SortShipmentsContext = React.createContext<{
  orderByField: ShipmentOrderField;
  orderByDirection: OrderDirection;
  setOrderByField: (value: ShipmentOrderField) => void;
  setOrderByDirection: (value: OrderDirection) => void;
  filterShipmentsWithoutLabels: boolean;
  setFilterShipmentsWithoutLabels: (value: boolean) => void;
  carriersFilter: Set<CarrierCode>;
  setCarriersFilter: (value: Set<CarrierCode>) => void;
  createdAt?: string;
  setCreatedAt: (value: string | undefined) => void;
  collectionDate?: string;
  setCollectionDate: (value: string | undefined) => void;
  deliveryDate?: string;
  setDeliveryDate: (value: string | undefined) => void;
  numFiltersEnabled: number;
  emptyState: string;
  filter?: string;
  setFilter: (filter: string) => void;
  carriers: ExternalCarrierPayloadFragment[];
  resetFilters: () => void;
  selectedLocation: CollectionLocationFragment | null;
  onSelectLocation: (
    selectedLocation: CollectionLocationFragment | null,
  ) => void;
  collectionLocations: CollectionLocations;
}>({
  orderByField: ShipmentOrderField.CollectionDate,
  setOrderByField: () => null,
  orderByDirection: OrderDirection.Desc,
  setOrderByDirection: () => null,
  createdAt: undefined,
  setCreatedAt: () => null,
  collectionDate: undefined,
  setCollectionDate: () => null,
  deliveryDate: undefined,
  setDeliveryDate: () => null,
  filterShipmentsWithoutLabels: false,
  setFilterShipmentsWithoutLabels: () => null,
  carriersFilter: new Set(),
  setCarriersFilter: () => null,
  numFiltersEnabled: 0,
  emptyState: '',
  filter: undefined,
  setFilter: () => null,
  carriers: [],
  resetFilters: () => null,
  selectedLocation: null,
  onSelectLocation: () => null,
  collectionLocations: [],
});

export const SortShipmentsProvider = ({
  children,
}: {
  children?: ReactNode;
}) => {
  const timezone = useOrganizationTimezone();
  const router = useRouter();
  const { carriers } = useListCarriers();

  const { locationId } = router.query;

  const { selectedLocation, onSelectLocation, collectionLocations } =
    useCollectionLocations();

  const {
    orderByField,
    setOrderByField,
    orderByDirection,
    setOrderByDirection,
    filterShipmentsWithoutLabels,
    setFilterShipmentsWithoutLabels,
    carriersFilter,
    setCarriersFilter,
    createdAt,
    setCreatedAt,
    collectionDate,
    setCollectionDate,
    deliveryDate,
    setDeliveryDate,
    filter,
    setFilter,
    resetFilters,
  } = useSortShipmentsState();

  const numFiltersEnabled = [
    filterShipmentsWithoutLabels,
    carriersFilter.size !== carriers.length,
    locationId,
    collectionDate,
    createdAt,
    deliveryDate,
  ].filter(Boolean).length;

  let emptyState = [
    'We couldn’t find any shipments ',
    filterShipmentsWithoutLabels ? 'with labels not yet printed' : '',
    createdAt
      ? ` imported on ${formatFriendlyDate(
          parsePlainDate(createdAt),
          timezone,
        )}`
      : '',
    collectionDate
      ? ` with a collection date of ${formatFriendlyDate(
          parsePlainDate(collectionDate),
          timezone,
        )}`
      : '',
    deliveryDate
      ? ` with a delivery date of ${formatFriendlyDate(
          parsePlainDate(deliveryDate),
          timezone,
        )}`
      : '',
    carriersFilter.size
      ? ` delivered by ${formatSelectedFilterCarriers(
          carriersFilter,
          carriers,
        )}`
      : '',
  ]
    .join('')
    .trim();

  if (!carriersFilter.size)
    emptyState =
      'No shipments to display. Please adjust your selected filters.';

  return (
    <SortShipmentsContext.Provider
      value={{
        orderByField,
        setOrderByField,
        orderByDirection,
        setOrderByDirection,
        filterShipmentsWithoutLabels,
        setFilterShipmentsWithoutLabels,
        carriersFilter,
        setCarriersFilter,
        numFiltersEnabled,
        createdAt,
        setCreatedAt,
        collectionDate,
        setCollectionDate,
        deliveryDate,
        setDeliveryDate,
        emptyState,
        filter,
        setFilter,
        carriers,
        resetFilters,
        selectedLocation,
        onSelectLocation,
        collectionLocations,
      }}
    >
      {children}
    </SortShipmentsContext.Provider>
  );
};

export const useSortShipments = () => {
  const context = useContext(SortShipmentsContext);

  return context;
};

const getDirectionLabelForField = (
  field: ShipmentOrderField,
  direction: OrderDirection,
) => {
  const isAscending = direction === OrderDirection.Asc;

  switch (field) {
    case ShipmentOrderField.CollectionDate:
    case ShipmentOrderField.CreatedAt:
      return isAscending ? 'Oldest first' : 'Newest first';
    case ShipmentOrderField.PostCode:
    case ShipmentOrderField.ExternalReference:
    case ShipmentOrderField.DeliveryLocationName:
      return isAscending ? 'A-Z' : 'Z-A';
    default:
      return isAscending ? 'Ascending' : 'Descending';
  }
};

const FilterShipmentsControls = () => {
  const {
    setFilterShipmentsWithoutLabels,
    filterShipmentsWithoutLabels,
    carriersFilter,
    setCarriersFilter,
    numFiltersEnabled,
    createdAt,
    setCreatedAt,
    collectionDate,
    setCollectionDate,
    deliveryDate,
    setDeliveryDate,
    carriers,
    resetFilters,
    selectedLocation,
    onSelectLocation,
    collectionLocations,
  } = useSortShipments();
  const timezone = useOrganizationTimezone();
  const today = plainDateToJSDate(todayLocal(timezone));

  const router = useRouter();
  const organization = useCurrentUser()?.organization;
  const urlParams = { ...router.query };

  // Clear initially selected shipments when filtering
  urlParams.selectedShipments = undefined;

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant="outline" onClick={() => undefined}>
          <ListFilter />
          <span className="hidden md:inline">Filter</span>
          {numFiltersEnabled > 0 ? (
            <>
              <Separator orientation="vertical" className="mx-2 h-4" />
              <Badge
                variant="secondary"
                className="rounded-sm px-1 font-normal lg:hidden"
              >
                {numFiltersEnabled}
              </Badge>
              <div className="hidden space-x-1 lg:flex">
                <Badge
                  variant="secondary"
                  className="rounded-sm px-1 font-normal"
                >
                  {numFiltersEnabled} selected
                </Badge>
              </div>
            </>
          ) : null}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="space-y-6 min-w-[368px]" align="start">
        <FormItem>
          <Label>Shipping labels</Label>
          <div className="flex-1 flex items-center gap-x-2">
            <Switch
              checked={filterShipmentsWithoutLabels}
              onCheckedChange={setFilterShipmentsWithoutLabels}
            />
            <Label>Labels not yet printed</Label>
          </div>
        </FormItem>
        {organization?.externalShipmentManagementEnabled ? (
          <FormItem
            data-testid="filter-shipments-carriers"
            className="capitalize"
          >
            <Label>Show selected carriers</Label>
            {carriers?.map((carrier) => (
              <FormItem
                key={`carrier-filter-${carrier.carrierCode}`}
                className="flex flex-row items-start space-x-3 space-y-0"
              >
                <Checkbox
                  id={`carrier-filter-checkbox-${carrier.carrierCode}`}
                  checked={!!carriersFilter.has(carrier.carrierCode)}
                  onCheckedChange={() => {
                    const newCarriersFilter = new Set(carriersFilter);
                    if (newCarriersFilter.has(carrier.carrierCode)) {
                      newCarriersFilter.delete(carrier.carrierCode);
                    } else {
                      newCarriersFilter.add(carrier.carrierCode);
                    }

                    setCarriersFilter(newCarriersFilter);
                  }}
                />

                <Label
                  htmlFor={`carrier-filter-checkbox-${carrier.carrierCode}`}
                >
                  <Shipment.ExternalCarrierLogo
                    carrierCode={carrier.carrierCode}
                  />
                </Label>
              </FormItem>
            ))}
          </FormItem>
        ) : null}
        <FormItem>
          <CalendarInput
            label="Collection date"
            onSelect={(date) =>
              setCollectionDate(date ? formatDate(date) : undefined)
            }
            selected={
              collectionDate ? parsePlainDate(collectionDate) : undefined
            }
            timezone={timezone}
            disabled={{ after: today }}
          />
        </FormItem>
        <FormItem>
          <CalendarInput
            label="Delivery date"
            onSelect={(date) =>
              setDeliveryDate(date ? formatDate(date) : undefined)
            }
            selected={deliveryDate ? parsePlainDate(deliveryDate) : undefined}
            timezone={timezone}
            disabled={{ after: today }}
          />
        </FormItem>
        <FormItem>
          <CalendarInput
            label="Imported on"
            onSelect={(date) =>
              setCreatedAt(date ? formatDate(date) : undefined)
            }
            selected={createdAt ? parsePlainDate(createdAt) : undefined}
            timezone={timezone}
            disabled={{ after: today }}
          />
        </FormItem>
        <FormItem>
          <Label>Collection location</Label>
          <CollectionLocationsSelector
            locations={collectionLocations}
            selectedLocation={selectedLocation}
            onChange={onSelectLocation}
          />
        </FormItem>
        {numFiltersEnabled ? (
          <FormItem>
            <div className="bg-border h-px" />
            <Button onClick={resetFilters} variant="ghost" className="w-full">
              Reset filters <X />
            </Button>
          </FormItem>
        ) : null}
      </PopoverContent>
    </Popover>
  );
};

const SortShipmentsControls = () => {
  const {
    setOrderByDirection,
    setOrderByField,
    orderByField,
    orderByDirection,
  } = useSortShipments();

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant="outline" onClick={() => undefined} aria-label="Sort">
          <ArrowUpDown />
          <span className="hidden md:inline">Sort</span>
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <div className="space-y-3 -my-1.5">
          <FormItem className="space-y-3">
            <Label>Sort by</Label>
            <RadioGroup
              defaultValue={orderByField}
              onValueChange={setOrderByField}
            >
              {ORDER_BY_OPTIONS.map((option) => (
                <FormItem
                  className="flex items-center space-x-3 space-y-0"
                  key={option.value}
                >
                  <RadioGroupItem value={option.value} id={option.value} />
                  <Label className="font-normal" htmlFor={option.value}>
                    {option.label}
                  </Label>
                </FormItem>
              ))}
            </RadioGroup>
            <Separator />
            <RadioGroup
              defaultValue={orderByDirection}
              onValueChange={setOrderByDirection}
            >
              {ORDER_DIRECTION_OPTIONS.map((direction) => (
                <FormItem
                  key={direction}
                  className={cn(
                    'flex items-center space-y-0 px-2 py-1.5 -mx-2 -my-0.5 rounded-sm hover:bg-primary-foreground-50',
                    direction === orderByDirection
                      ? 'bg-primary-foreground'
                      : '',
                  )}
                >
                  <RadioGroupItem
                    value={direction}
                    id={direction}
                    className="sr-only"
                  />
                  <Label
                    className="font-normal flex gap-3 cursor-pointer"
                    htmlFor={direction}
                  >
                    {direction === OrderDirection.Asc ? (
                      <ArrowUp className="size-4" />
                    ) : (
                      <ArrowDown className="size-4" />
                    )}
                    {getDirectionLabelForField(orderByField, direction)}
                  </Label>
                </FormItem>
              ))}
            </RadioGroup>
          </FormItem>
        </div>
      </PopoverContent>
    </Popover>
  );
};

export const SortShipments = () => {
  const { numFiltersEnabled, resetFilters } = useSortShipments();

  return (
    <div className={cn('flex gap-2')}>
      <FilterShipmentsControls />
      <SortShipmentsControls />
      {numFiltersEnabled ? (
        <Button onClick={resetFilters} variant="ghost" className="w-full">
          Reset filters <X />
        </Button>
      ) : null}
    </div>
  );
};
