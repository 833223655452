import { TZ_EUROPE_LONDON, todayLocal } from '@packfleet/datetime';
import { GetServerSidePropsContext } from 'next';
import { useRouter } from 'next/router';
import Dashboard from '../../../components/page-components/dashboard/Dashboard';
import {
  DashboardDocument,
  DashboardQuery,
  DashboardQueryVariables,
  useDashboardQuery,
  useReadCollectionTrackingInfoQuery,
} from '../../../generated/graphql';
import { useOrganizationTimezone } from '../../../hooks/timezone';
import { formatDate } from '../../../utilities/date';
import { listQueryToNodes } from '../../../utilities/graphql';
import {
  fetchGraphQL,
  getSSRApolloClient,
  ssrGraphQLRequest,
} from '../../../utilities/request/ssr-graphql';
import { requireAuthentication } from '../../../utilities/require-authentication';
import {
  NUM_SHIPMENTS_TO_FETCH_ASYNC,
  NUM_SHIPMENTS_TO_FETCH_BACKEND,
} from '../shipments/useShipments';

DashboardPage.getInitialProps = async ({ req }: GetServerSidePropsContext) => {
  const client = getSSRApolloClient(req);
  return ssrGraphQLRequest(client, [
    fetchGraphQL<DashboardQuery, DashboardQueryVariables>(DashboardDocument, {
      date: formatDate(todayLocal(TZ_EUROPE_LONDON)),
      numShipments: NUM_SHIPMENTS_TO_FETCH_BACKEND,
    }),
  ]);
};

function DashboardPage() {
  const router = useRouter();
  const timezone = useOrganizationTimezone();
  const { id } = router.query;
  const todayFmt = formatDate(todayLocal(timezone));

  const { data, loading: dashboardLoading } = useDashboardQuery({
    variables: {
      date: todayFmt,
      numShipments: NUM_SHIPMENTS_TO_FETCH_ASYNC,
    },
    fetchPolicy: 'cache-and-network',
  });
  const { data: trackingData } = useReadCollectionTrackingInfoQuery({
    variables: {
      input: {
        date: todayFmt,
      },
    },
    pollInterval: 30000,
  });

  const collections = data?.listCollections?.collections ?? [];
  const shipments = listQueryToNodes(data?.listShipments);
  const collectionLocations =
    data?.listCollectionLocations?.collectionLocations ?? [];
  const collectionTrackingInfos =
    trackingData?.readCollectionTrackingInfo?.trackingInfo ?? [];
  const claims = data?.listClaims?.claims ?? [];

  return (
    <Dashboard
      locationId={id !== undefined ? (id as string) : undefined}
      shipments={shipments}
      collections={collections}
      collectionLocations={collectionLocations}
      collectionTrackingInfos={collectionTrackingInfos}
      hasAnyShipments={!!shipments.length}
      claims={claims}
      loading={dashboardLoading}
    />
  );
}
export default requireAuthentication(DashboardPage);
