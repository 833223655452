import { captureException } from '@sentry/nextjs';
import React, { ReactNode, useState } from 'react';
import { setErrorToast } from '../ui/use-error';
import Alert from './Alert';

export type Props = {
  button: React.ReactElement;
  title: string;
  body?: ReactNode;
  confirmText: string;
  cancelText?: string;
  onConfirm: () => Promise<void>;
  onCancel?: () => void;
  onOpen?: () => Promise<void>;
  loading?: boolean;
};

export const WithAlert = ({ button, onOpen, ...props }: Props) => {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  return (
    <>
      {React.cloneElement(button, {
        onClick: async () => {
          setConfirmLoading(false);

          if (onOpen) {
            setLoading(true);
            try {
              await onOpen();
            } finally {
              setLoading(false);
            }
          }
          setShow(true);
        },
        loading,
      })}
      <Alert
        {...props}
        show={show}
        body={
          <div>
            <p className="text-sm text-muted-foreground">{props.body}</p>
          </div>
        }
        onConfirm={async () => {
          try {
            setConfirmLoading(true);
            await props.onConfirm();
            setShow(false);
          } catch (error) {
            captureException(error);
            setErrorToast({
              message: 'Sorry, something went wrong. Please try again later.',
            });
          } finally {
            setConfirmLoading(false);
          }
        }}
        onCancel={() => {
          setShow(false);
          if (props.onCancel) props.onCancel();
        }}
        loading={confirmLoading}
      />
    </>
  );
};

export default WithAlert;
