import { useCountThirdPartyAppImportableShipmentsQuery } from '@/generated/graphql';
import { useIsBrandUser } from '@/hooks/useCurrentUser';
import { usePollWhenFocused } from '@packfleet/hooks';

export const useCountImportableShipments = () => {
  const { hasUserLoaded, isBrandUser } = useIsBrandUser();

  const variables = {
    input: {
      excludeErrors: true,
      excludeWarnings: true,
    },
  };

  // Brand users are not able to view importable shipments as they are not associated with
  // any brand (at least for now)
  const skip = !hasUserLoaded || isBrandUser;
  const { data, refetch } = useCountThirdPartyAppImportableShipmentsQuery({
    variables,
    skip,
    fetchPolicy: 'network-only',
  });

  usePollWhenFocused({
    refetch,
    interval: 10 * 60 * 1000, // 10 minutes
    skip,
  });

  const totalImportableShipments =
    data?.countThirdPartyAppImportableShipments?.count;
  const importableShipmentsIsPaginated =
    data?.countThirdPartyAppImportableShipments?.hasMoreData;

  return {
    totalImportableShipments,
    importableShipmentsIsPaginated,
  };
};
