import { ToastAction } from './toast';
import { toast } from './use-toast';

export type SetErrorProps = {
  message: string;
  description?: string;
  action?: {
    label: string;
    onClick: () => void | Promise<void>;
  };
};

// setErrorToast({ message: 'Error message', description: 'Error description', action: { label: 'Retry', onClick: () => undefined } })
export function setErrorToast({ message, description, action }: SetErrorProps) {
  toast({
    title: message,
    description,
    variant: 'destructive',
    duration: 30000,
    action: action ? (
      <ToastAction altText={action.label} onClick={action.onClick}>
        {action.label}
      </ToastAction>
    ) : undefined,
  });
}
